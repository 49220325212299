import { PlatformAdapter, platformAdapter } from '@empathyco/x-adapter-platform';
import { resultSchemaOverride } from './mappers/schemas/results';
import { searchRequestSchemaOverride } from './mappers/schemas/search';
import { recommendationsRequestSchemaOverride } from './mappers/schemas/recommendations';
import { semanticQueriesRequestSchemaOverride } from './mappers/schemas/semantic-queries';
import { facetSchemaOverride } from './mappers/schemas/facets';
import { verticals } from './endpoints/verticals';
import { storeCounter } from './endpoints/store-counter';

/**
 * Initializing all schema overrides.
 *
 * @remarks This IIFE function is being executed immediately.
 */
(function initSchemaOverrides(): void {
  resultSchemaOverride();
  searchRequestSchemaOverride();
  recommendationsRequestSchemaOverride();
  semanticQueriesRequestSchemaOverride();
  facetSchemaOverride();
})();

export const adapter: PlatformAdapter = {
  ...platformAdapter,
  verticals: verticals,
  storeCounter: storeCounter
};
