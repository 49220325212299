import { endpointAdapterFactory, schemaMapperFactory } from '@empathyco/x-adapter';

export const storeCounter = endpointAdapterFactory<any, any>({
  endpoint:
    'https://search.internal.test.empathy.co/query/mymotivemarketplace/store-counter?lang=es',
  responseMapper: schemaMapperFactory<any, any>({
    totalProducts: response => response.catalog.numFound ?? 300000,
    totalShops: response => response.catalog.counts.total_stores ?? 127
  })
});
