import { CustomSearchXStoreModule } from '../types';

/**
 * Default implementation for the {@link CustomSearchGetters.request} getter.
 *
 * @param state - Current {@link https://vuex.vuejs.org/guide/state.html | state} of the search
 * module.
 * @param getters - Current {@link https://vuex.vuejs.org/guide/getters.html | getters} of the
 * search module.
 * @returns The search request to fetch data from the API.
 *
 * @public
 */
export const request: CustomSearchXStoreModule['getters']['request'] = (
  {
    page,
    params,
    selectedFilters: filters,
    sort,
    latitude,
    longitude,
    radius,
    singleShopQuery,
    singleShopName,
    singleShopPage,
    config
  },
  { query }
) => {
  return query || singleShopQuery
    ? {
        query: singleShopQuery ? singleShopQuery : query,
        sort,
        page: singleShopQuery ? singleShopPage : page,
        filters: singleShopQuery ? undefined : filters,
        extraParams: singleShopQuery
          ? {
              ...params,
              filter: `store:${singleShopName}`,
              start: (singleShopPage - 1) * config.pageSize,
              rows: config.pageSize
            }
          : params,
        latitude,
        longitude,
        radius
      }
    : null;
};
