import { CustomSearchXStoreModule } from '../types';

// eslint-disable-next-line max-len
export const increasePageAppendingResults: CustomSearchXStoreModule['actions']['increasePageAppendingResults'] =
  ({ commit, state }) => {
    if (state.singleShopQuery) {
      const newPage = state.singleShopPage + 1;
      if (
        newPage >= 1 &&
        state.singleShopPage * state.config.pageSize < state.singleShopTotalResults
      ) {
        commit('setSingleShopPage', newPage);
        commit('setIsAppendResults', true);
      }
    } else {
      const newPage = state.page + 1;
      if (newPage >= 1 && state.page * state.config.pageSize < state.totalResults) {
        commit('setPage', newPage);
        commit('setIsAppendResults', true);
      }
    }
  };
