import { resultSchema } from '@empathyco/x-adapter-platform';
import { Result } from '@empathyco/x-types';
import { MyMotiveResult } from '../../models/entitites/result';
import { getEncodedSignature } from '../../../utils/encryption';

/**
 * Auxiliar function to get the img proxy service URL.
 *
 * @returns The URL of the images proxy depending on the current app
 * enviroment (staging, prod and test by default).
 */
function getProxyServiceURL(): string {
  if (document.location.host.match(/sta.motivemarket\./)) {
    return process.env.VUE_APP_IMAGE_PROXY_SERVICE_STAGING;
  }
  if (document.location.host.match(/motivemarket\./)) {
    return process.env.VUE_APP_IMAGE_PROXY_SERVICE_PROD;
  }

  return process.env.VUE_APP_IMAGE_PROXY_SERVICE_TEST;
}

/**
 * Maps result images by prepending the proxy service URL to each one.
 *
 * @param images - The list of images to map.
 * @returns A list of mapped images.
 */
function mapImages(images: string[]): string[] {
  return images.map(imageURL => getProxyServiceURL() + getEncodedSignature(imageURL));
}

/**
 * Overrides {@link Result} schema properties with {@link MyMotiveResult}
 * extra properties.
 */
export const resultSchemaOverride = (): void => {
  resultSchema.$override<MyMotiveResult, Partial<Result>>({
    city: 'city',
    brand: 'brand',
    store: 'store',
    shortDescription: 'shortDescription',
    images: ({ images }) => mapImages(images),
    category: ({ category }) => (category ? category.replaceAll('_', ' ') : ''),
    location: 'location',
    address: 'address'
  });
};
