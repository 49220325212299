import { InstallXOptions, SnippetConfig } from '@empathyco/x-components';
import { I18n, cssInjector } from '@empathyco/x-archetype-utils';
import App from '../App.vue';
import * as messages from '../i18n/messages';
import store from '../store';
import { adapter } from '../adapter/adapter';
import { useDevice } from '../composables/use-device.composable';
import { searchCustomStoreModule } from '../x-modules/custom-search-module/module';
import { customSearchWiring } from '../x-modules/custom-search-module/wiring';

const device = useDevice();

export const installXOptions: InstallXOptions = {
  adapter,
  store,
  app: App,
  domElement: getDomElement,
  __PRIVATE__xModules: {
    search: {
      storeModule: searchCustomStoreModule as any
    }
  },
  xModules: {
    facets: {
      config: {
        filtersStrategyForRequest: 'leaves-only'
      }
    },
    semanticQueries: {
      config: {
        threshold: 50,
        maxItemsToRequest: 10
      }
    },
    search: {
      wiring: customSearchWiring,
      config: {
        pageSize: 24
      }
    },
    url: {
      wiring: {
        PageChanged: undefined
      }
    }
  },
  async installExtraPlugins({ vue, snippet }) {
    const i18n = await I18n.create({
      locale: snippet.uiLang,
      device: (snippet.device as string) ?? device.deviceName.value,
      fallbackLocale: 'en',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      messages
    });
    vue.use(i18n);
    vue.prototype.$setLocale = i18n.setLocale.bind(i18n);
    vue.prototype.$setLocaleDevice = i18n.setDevice.bind(i18n);

    return {
      i18n: i18n.vueI18n
    };
  }
};

/**
 * Creates a DOM element to mount the X Components app.
 *
 * @param snippetConfig - The snippet configuration.
 * @returns The DOM element.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getDomElement({ isolate }: SnippetConfig): Element {
  const domElement = document.createElement('div');

  // eslint-disable-next-line no-constant-condition
  if (false) {
    const container = document.createElement('div');
    const shadowRoot = container.attachShadow({ mode: 'open' });
    shadowRoot.appendChild(domElement);
    document.body.appendChild(container);
    cssInjector.setHost(shadowRoot);
  } else {
    document.body.appendChild(domElement);
    cssInjector.setHost(document.head);
  }

  return domElement;
}
