import { namespacedWireCommit, namespacedWireDispatch, Wiring } from '@empathyco/x-components';
import { SearchActions, SearchMutations } from '@empathyco/x-components/search';

const wireDispatch = namespacedWireDispatch('search');
const wireCommit = namespacedWireCommit('search');

export const setMapParameters: any = wireDispatch('setMapParameters' as keyof SearchActions);
export const fetchAndSaveStoreCounterResponseWire: any = wireDispatch(
  'fetchAndSaveStoreCounterResponse' as keyof SearchActions
);
export const ClearSingleShopSearchParameters = wireDispatch(
  'clearSingleShopSearchBox' as keyof SearchActions
);

export const setSearchSingleShopQuery = wireCommit('setSingleShopQuery' as keyof SearchMutations);
export const setSearchSingleShopName = wireCommit('setSingleShopName' as keyof SearchMutations);
export const resetSingleShopState = wireCommit('resetSingleShopState' as keyof SearchMutations);

export const customSearchWiring: Partial<Wiring> = {
  MapParamsProvided: { setMapParameters },
  UserAcceptedASingleShopQuery: { setSearchSingleShopQuery },
  UserClickedASingleShop: { setSearchSingleShopName },
  ClearSingleShopSearchBox: { ClearSingleShopSearchParameters },
  resetSingleShopState: { resetSingleShopState },
  UserOpenXProgrammatically: {
    fetchAndSaveStoreCounterResponseWire
  },
  UserClickedOpenX: {
    fetchAndSaveStoreCounterResponseWire
  }
};
