import { endpointAdapterFactory, schemaMapperFactory } from '@empathyco/x-adapter';

export const verticals = endpointAdapterFactory<any, any>({
  endpoint: 'https://api.staging.empathy.co/search/v1/query/mymotivemarketplace/facet-values',
  responseMapper: schemaMapperFactory<any, any>({
    verticals: response => response.catalog.facets[0]?.values[0]?.children?.values ?? []
  }),
  defaultRequestOptions: {
    id: 'verticals',
    parameters: {
      internal: true,
      lang: 'es',
      browseField: 'categoryIds',
      browseValue: '2fc8494bd',
      filter: 'categoryIds:2fc8494bd'
    }
  }
});
