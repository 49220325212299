import { PlatformSearchRequest, searchRequestSchema } from '@empathyco/x-adapter-platform';
import { MyMotiveSearchRequest } from '../../models/request/search';

/**
 * Overrides {@link PlatformSearchRequest} schema properties with {@link MyMotiveSearchRequest}
 * extra properties.
 */
export const searchRequestSchemaOverride = (): void => {
  searchRequestSchema.$override<MyMotiveSearchRequest, Partial<PlatformSearchRequest>>({
    longitude: 'longitude',
    latitude: 'latitude',
    radius: 'radius'
  });
};
