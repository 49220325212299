import { Result } from '@empathyco/x-types';
import { CustomSearchXStoreModule } from '../types';

/**
 * Default implementation for the SearchActions.SaveSearchResponse.
 *
 * @param context - The {@link https://vuex.vuejs.org/guide/actions.html | context} of the actions,
 * provided by Vuex.
 * @param response - The {@link @empathyco/x-types#SearchResponse} to save.
 *
 * @public
 */
export const saveSearchResponse: CustomSearchXStoreModule['actions']['saveSearchResponse'] = (
  { commit, state, getters },
  {
    results,
    partialResults,
    facets,
    banners,
    promoteds,
    totalResults,
    spellcheck,
    redirections,
    queryTagging
    // displayTagging -> we don't know where it comes from
  }
) => {
  if (state.singleShopQuery) {
    if (state.isAppendResults) {
      commit('appendSingleShopResults', results as Result[]);
      commit('setIsAppendResults', false);
    } else {
      commit('setSingleShopResults', results as Result[]);
      commit('setIsSingleShopNoResults', !results.length);
      commit('setSingleShopTotalResults', totalResults);
    }
  } else {
    if (totalResults === 0) {
      commit('setIsNoResults', true);
      if (getters.request && Object.keys(getters.request.filters!).length > 0) {
        commit('setFromNoResultsWithFilters', true);
      }
    } else {
      commit('setIsNoResults', false);
    }

    if (state.isAppendResults) {
      commit('appendResults', results);
    } else {
      commit('setResults', results);
      commit('setBanners', banners ?? []);
      commit('setPromoteds', promoteds ?? []);
      commit('setRedirections', redirections ?? []);
    }

    commit('setPartialResults', partialResults ?? []);

    if (facets) {
      commit('setFacets', facets);
    }

    if (queryTagging) {
      commit('setQueryTagging', queryTagging);
    }

    // if (displayTagging) {
    //   commit('setDisplayTagging' as keyof SearchMutations, displayTagging);
    // }

    commit('setTotalResults', totalResults);
    commit('setSpellcheck', spellcheck ?? '');
  }
};
