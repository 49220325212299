import { CustomSearchXStoreModule } from '../types';

export const setMapParameters: CustomSearchXStoreModule['actions']['setMapParams'] = (
  { commit },
  { longitude, latitude, radius }
) => {
  commit('setLongitude', longitude);
  commit('setLatitude', latitude);
  commit('setRadius', radius);
};
