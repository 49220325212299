import { Result } from '@empathyco/x-types';
import { request } from './getters/request.getter';
import { setMapParameters } from './actions/setMapParameters.action';
import { saveSearchResponse } from './actions/save-search-response.action';
import { clearSingleShopSearchBox } from './actions/clear-single-shop-search-box.action';
import { increasePageAppendingResults } from './actions/increase-page-apending-results.action';
import { fetchStoreCounterResponse } from './actions/fetch-store-counter-response.action';
import { saveStoreCounterResponse } from './actions/save-store-counter-response.action';
import { fetchAndSaveStoreCounterResponse } from './actions/fetch-and-save-store-counter.action';

export const searchCustomStoreModule = {
  state: {
    latitude: 0,
    longitude: 0,
    radius: 999999,
    singleShopResults: [],
    singleShopTotalResults: 0,
    singleShopPage: 1,
    singleShopQuery: '',
    singleShopName: '',
    isSingleShopNoResults: false,
    totalProducts: '300.000',
    totalShops: '120'
  },
  getters: {
    request
  },
  mutations: {
    setLatitude(state: { latitude: number }, latitude: number) {
      state.latitude = latitude;
    },
    setLongitude(state: { longitude: number }, longitude: number) {
      state.longitude = longitude;
    },
    setRadius(state: { radius: number }, radius: number) {
      state.radius = radius;
    },
    setSingleShopPage(state: { singleShopPage: number }, singleShopPage: number) {
      state.singleShopPage = singleShopPage;
    },
    setSingleShopResults(state: { singleShopResults: Result[] }, singleShopResults: Result[]) {
      state.singleShopResults = singleShopResults;
    },
    appendSingleShopResults(state: { singleShopResults: Result[] }, singleShopResults: Result[]) {
      state.singleShopResults.push(...singleShopResults);
    },
    setSingleShopTotalResults(
      state: { singleShopTotalResults: number },
      singleShopTotalResults: number
    ) {
      state.singleShopTotalResults = singleShopTotalResults;
    },
    setSingleShopQuery(state: { singleShopQuery: string }, singleShopQuery: string) {
      state.singleShopQuery = singleShopQuery;
    },
    setSingleShopName(state: { singleShopName: string }, singleShopName: string) {
      state.singleShopName = singleShopName;
    },
    setIsSingleShopNoResults(
      state: { isSingleShopNoResults: boolean },
      isSingleShopNoResults: boolean
    ) {
      state.isSingleShopNoResults = isSingleShopNoResults;
    },
    setTotalProducts(state: { totalProducts: string }, totalProducts: number) {
      const roundedValue = Math.round(totalProducts / 10000) * 10000;
      state.totalProducts = roundedValue.toLocaleString('es');
    },
    setTotalShops(state: { totalShops: string }, totalShops: number) {
      state.totalShops = totalShops.toString();
    },
    resetSingleShopState(state: {
      singleShopResults: Result[];
      singleShopQuery: string;
      singleShopName: string;
      isSingleShopNoResults: boolean;
    }) {
      state.singleShopResults = [];
      state.singleShopQuery = '';
      state.singleShopName = '';
      state.isSingleShopNoResults = false;
    }
  },
  actions: {
    setMapParameters,
    saveSearchResponse,
    clearSingleShopSearchBox,
    increasePageAppendingResults,
    fetchStoreCounterResponse,
    saveStoreCounterResponse,
    fetchAndSaveStoreCounterResponse
  }
};
